export enum OnBoardingPage {
    LoginOptions = "Login",
    SignUpOptions = "SignUp",
    EmailLogin = "EmailLogin",
    EmailSignUp = "EmailSignUp",
    DownloadGPX = "DownloadGPX",
    DownloadPrintMaps = "DownloadPrintMaps",
    DownloadApp = "DownloadApp",
    AddTrailToList = "AddTrailToList",
    ConfirmDetails = "ConfirmDetails",
    Success = "Success",
}

export class OnBoardingPagesUtility {
    onBoardingPage: OnBoardingPage

    constructor(onBoardingPage: OnBoardingPage) {
        this.onBoardingPage = onBoardingPage
    }

    static fromString(onBoardingPage: string): OnBoardingPagesUtility {
        switch (onBoardingPage) {
            case "SignUp":
                return new OnBoardingPagesUtility(OnBoardingPage.SignUpOptions)
            case "Login":
                return new OnBoardingPagesUtility(OnBoardingPage.LoginOptions)
            case "EmailSignUp":
                return new OnBoardingPagesUtility(OnBoardingPage.EmailSignUp)
            case "EmailLogin":
                return new OnBoardingPagesUtility(OnBoardingPage.EmailLogin)
            case "DownloadGPX":
                return new OnBoardingPagesUtility(OnBoardingPage.DownloadGPX)
            case "DownloadPrintMaps":
                return new OnBoardingPagesUtility(OnBoardingPage.DownloadPrintMaps)
            case "DownloadApp":
                return new OnBoardingPagesUtility(OnBoardingPage.DownloadApp)
            case "ConfirmDetails":
                return new OnBoardingPagesUtility(OnBoardingPage.ConfirmDetails)
            case "AddTrailToList":
                return new OnBoardingPagesUtility(OnBoardingPage.AddTrailToList)
            case "Success":
                return new OnBoardingPagesUtility(OnBoardingPage.Success)
        }
        throw Error("Invalid OnBoardingPage")
    }

    title() {
        switch (this.onBoardingPage) {
            case OnBoardingPage.SignUpOptions:
                return "Sign up title"
            case OnBoardingPage.LoginOptions:
                return "Login title"
            case OnBoardingPage.EmailLogin:
                return "Email title"
            case OnBoardingPage.DownloadApp:
                return "Download app title"
            case OnBoardingPage.DownloadGPX:
                return "Download GPX title"
            case OnBoardingPage.DownloadPrintMaps:
                return "Download print maps title"
            case OnBoardingPage.ConfirmDetails:
                return "Confirm details title"
            case OnBoardingPage.AddTrailToList:
                return "Bucket list trails, big goals or completed trails!"
            case OnBoardingPage.Success:
                return "Success title"
        }
    }

    body() {
        switch (this.onBoardingPage) {
            case OnBoardingPage.SignUpOptions:
                return "Sign up body"
            case OnBoardingPage.LoginOptions:
                return "Login body"
            case OnBoardingPage.EmailLogin:
                return "Email body"
            case OnBoardingPage.DownloadApp:
                return "Download app body"
            case OnBoardingPage.DownloadGPX:
                return "Download GPX body"
            case OnBoardingPage.DownloadPrintMaps:
                return "Download print maps body"
            case OnBoardingPage.ConfirmDetails:
                return "Confirm details body"
            case OnBoardingPage.AddTrailToList:
                return "We will also sync these trails to your HiiKER app so they’re ready for you to hit the trail!"
            case OnBoardingPage.Success:
                return "Success body"
        }
    }
}
