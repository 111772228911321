import store from './store/store'
import Vue from "vue";
import OnBoarding from './OnBoarding.vue';
import {OnBoardingGoal} from "../utils/models/OnBoardingGoal";

declare global {
  interface Window {
    onBoardingVue: Vue;
    slug: string;
    region_slug: string;
    country_slug: string;
    id: number;
    stripeApiKey: string;
  }
}

const onBoardingEl = document.getElementById('on_boarding');

if(onBoardingEl) {
  window.onBoardingVue = new Vue({
    store,
    render: (createElement) => {
      return createElement(OnBoarding)
    },
    async beforeCreate() {
      await this.$store.dispatch('onBoarding/initialiseStore')
      await this.$store.dispatch('onBoarding/updateUserForFb')
    },
    methods: {
      async launchFor(goal: OnBoardingGoal) {
        await this.$store.dispatch('onBoarding/setupFlowFor', goal)
      },
    }
  }).$mount('#on_boarding');
}
