<template>
  <div>
    <span class="badge badge-secondary mb-1 mb-4 py-1 pr-3">
      <div class="d-flex align-items-center" style="cursor: pointer" @click="backTapped">
        <span class="material-icons mr-1">arrow_left</span> Back
      </div>
    </span>
    <form>
      <div class="form-group mb-3">
        <label class="m-0" for="exampleInputEmail1">Email address</label>
        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
          placeholder="example@email.com" v-model="email">
      </div>
      <div class="form-group mb-3">
        <label class="m-0" for="exampleInputPassword1">Password</label>
        <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password"
          v-model="password">
      </div>
      <button class="btn btn-primary" :disabled="loading" @click="login">
        <template v-if="loading">
          <i class="fa fa-spinner fa-spin"></i> Loading...
        </template>
        <template v-else>
          Submit
        </template>
      </button>
      <a class="pl-3" style="cursor: pointer" @click="resetPassword">Reset password</a>
    </form>
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue';
import {isValidEmail} from "../../utils/helpers/IsValidEmail";

export default defineComponent({
  data() {
    return {
      email: '',
      password: '',
      loading: false
    };
  },
  methods: {
    backTapped() {
      this.$store.commit('onBoarding/popPage');
    },
    async login(event: Event) {
      this.loading = true;
      event.preventDefault();
      await this.$store.dispatch('onBoarding/loginWithEmail', {
        email: this.email,
        password: this.password,
      });
      this.loading = false
    },
    resetPassword() {
      if (this.email == "" || isValidEmail(this.email)) {
        this.$store.dispatch('onBoarding/resetPassword', this.email);
      } else {
        alert("Please enter a valid email address above then click on reset password.")
      }
    }
  },
});
</script>
