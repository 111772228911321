import {gql} from "graphql-request";

export const UpdateUser = gql`
  mutation UpdateUser($user:UpdateUserInput!) {
    updateUser(input: $user) {
      id
      displayName
      fullName
      email
      avatar {
        lgUrl
        metadata
      }
      measurementPreference
      mapOrientation
      overZoom
      unitPreference
      hikeTime
      emailNotification
      pushNotification
    }
  }
`;

export const GetUser = gql`
  query GetUser($firebaseID:String!) {
    user(firebaseUserId:$firebaseID){
      id
      title
      fullName
      displayName
      email
      avatar {
        mdUrl
        metadata
      }
      appSettings {
        name
        active
      }
      admin
      likesDayTrails
      likesMultidayTrails
      likesOvernightTrails
      viewedTrails {
        id
        trail {
          id
          name
          jumboImage {
            id
            url
            metadata
          }
          trailDistance
          totalElevation
          difficulty
          averageRating
          numberOfRatings
          durationString
          shortAddress
        }
      }
      measurementPreference
      mapOrientation
      overZoom
      unitPreference
      hikeTime
      gridReference
      emailNotification
      pushNotification
    }
  }
`;

export const GetUserTrailLists = gql`
  query GetUserTrailLists {
    userTrailLists {
      id
      name
          createdAt
      trails {
        id
        name
        jumboImage {
          id
          url
          metadata
        }
        trailDistance
        totalElevation
        difficulty
        averageRating
        numberOfRatings
        durationString
        shortAddress
      }
    }
  }
`;

export const CreateTrailList = gql`
  mutation createTrailList($trailList:CreateTrailListInput!) {
    createTrailList(input:$trailList) {
      id
      name
      errors {
        fieldName
        errors
      }
    }
  }
`;


export const UpdateTrailList = gql`
  mutation updateTrailList($trailList:UpdateTrailListInput!) {
    updateTrailList(input:$trailList) {
      id
      errors {
        fieldName
        errors
      }
    }
  }
`;

export const DestroyTrailList = gql`
  mutation destroyTrailList($trailList:DestroyTrailListInput!) {
    destroyTrailList(input:$trailList) {
      id
      destroyed
      errors {
        fieldName
        errors
      }
    }
  }
`;
