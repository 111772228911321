<template>
  <div>
    <h3 class="mb-4"><strong>Add to list</strong></h3>

    <div v-for="trailList of this.usersTrailLists" :key="trailList.id">
      <div class="row p-0 m-0">
        <div class="input-group col-12 p-0 m-0 d-flex align-items-center">
          <span class="form-control py-2 border-0"><h3 class="m-0">{{trailList.name}}</h3></span>
          <span class="input-group-append">
          <button class="btn btn-block btn-link d-flex align-items-center" type="button" @click="updateTrailList(trailList)">
            <template v-if="trailListIncludesTrail(trailList)">
              <span class="material-icons text-primary">check</span>
            </template>
            <template v-else>
              <span class="material-icons text-muted">add</span>
            </template>
          </button>
        </span>
        </div>
      </div>
      <div class="row p-0 m-0">
        <div class="col-12 p-0 m-0">
          <hr class="my-1">
        </div>
      </div>
    </div>

    <div class="row p-0 m-0">
      <div class="input-group col-12 p-0 m-0 d-flex align-items-center">
        <input class="form-control py-2 border-0" placeholder="Create a new list" @keyup.enter="createTrailList" v-model="trailListName">
        <span class="input-group-append">
          <button class="btn btn-block btn-link d-flex align-items-center" type="button" @click="createTrailList">
            <span class="material-icons text-muted">add</span>
          </button>
        </span>
      </div>
    </div>

    <div class="row p-0 m-0">
      <div class="col-12 p-0 m-0">
        <hr class="my-1">
      </div>
    </div>
    <!-- <button class="btn btn-primary" @click="closeClicked">Close</button> -->
  </div>
</template>

<script>

import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      trailListName: '',
    };
  },
  async mounted() {
    await this.$store.dispatch('onBoarding/getUserTrailLists');
  },
  computed: {
    usersTrailLists: {
      get() {
        return this.$store.state.onBoarding.usersTrailLists;
      },
    },
    trailId: {
      get() {
        return this.$store.state.onBoarding.trailId;
      },
    },
  },
  methods: {
    updateTrailList(trailList) {
      this.$store.dispatch('onBoarding/updateTrailList', trailList);
    },
    async createTrailList() {
      if (this.trailListName === '') {
        return alert('Please add a name for your list');
      }
      try {
        await this.$store.dispatch('onBoarding/createTrailList', this.trailListName);
        this.trailListName = '';
      } catch (e) {
        alert(e);
      }
    },
    async closeClicked() {
      this.$modal.classList.toggle('open');
    },
    trailListIncludesTrail(trailList) {
      return trailList.trails.some((trail) => parseInt(trail.id) === this.trailId);
    },
  },
});
</script>

<style scoped>

</style>
