<template>
  <div>
    <img class="mx-auto d-block mb-3" src="../../img/onboarding-app-preview.png" alt="HiiKER app preview" width="170">
    <h1 class="text-center">
      <strong>Get the best experience on the HiiKER App</strong>
    </h1>

    <p class="text-center mb-1">
      <strong>Plan, map</strong> and <strong>track</strong>
      your hikes on the go, with the essential hiking toolkit.
      <strong>Download the app now!</strong>
    </p>

    <p class="d-flex justify-content-center">
      <span class="text-yellow d-flex align-items-center">
        <span class="mr-2 display-5">4.7</span>
        <span class="material-icons">grade</span>
        <span class="material-icons">grade</span>
        <span class="material-icons">grade</span>
        <span class="material-icons">grade</span>
        <span class="material-icons">grade</span>
      </span>
    </p>

    <div class="row d-flex align-items-center">
      <div class="col-3 d-none d-md-block">
        <img class="img-fluid" src="../../img/qr-code-app-page.png" alt="HiiKER app download QR code">
        <p><small class="text-muted text-center d-block">SCAN ME</small></p>
      </div>
      <div class="col">
        <a target="_blank"
          data-ahoy-event="download_click"
          data-ahoy-store="playstore"
          :data-ahoy-url="locationHref"
          href="https://play.google.com/store/apps/details?id=com.waymarkedtrails.hiiker&hl=en_IE&gl=US">
          <img class="img-fluid" src="../../img/google-play-btn-white.png" alt="HiiKER app download QR code">
        </a>
      </div>
      <div class="col">
        <a target="_blank"
          data-ahoy-event="download_click"
          data-ahoy-store="appstore"
          :data-ahoy-url="locationHref"
          href="https://apps.apple.com/ie/app/hiiker-hiking-trails/id1470810597">
          <img class="img-fluid" src="../../img/app-app-store-btn-white.png" alt="HiiKER app download QR code">
        </a>
      </div>
    </div>
    <hr class="my-3">
  </div>
</template>

<script>
import ahoy from 'ahoy.js';
import { RevenueCatUtil } from "../../utils/models/RevenueCatUser";
import { OnBoardingPage } from "../../utils/models/OnBoardingPage";
import {RevenueCatWrapper} from "../../utils/RevenueCatWrapper";

export default {
  name: 'OnBoardingSuccessPage',
  data() {
    return {
      buttonIcon: 'add',
      buttonClass: 'btn-primary',
      buttonText: 'ADD TO PLANNED TRAILS',
    };
  },
  mounted() {
    ahoy.track('onboarding_success_page_mounted');
    window.bindAhoyEvents();
  },
  computed: {
    locationHref() {
      return window.location.href;
    },
  },
  methods: {
    async addToPlannedTrails() {
      await this.$store.dispatch('onBoarding/copyCuratedTrailToPlannedTrails');
      this.buttonIcon = 'check';
      this.buttonClass = 'btn-primary disabled';
      this.buttonText = 'SUCCESS';
    },
    async checkoutPro() {
      await this.$store.commit('onBoarding/pushPage', OnBoardingPage.PurchaseOptionsPro);
    },
    async checkoutProPlus() {
      await this.$store.commit('onBoarding/pushPage', OnBoardingPage.PurchaseOptionsProPlus);
    },
  },
};
</script>
