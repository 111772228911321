import { Purchases } from "@revenuecat/purchases-js";

export class RevenueCatWrapper {
  static async isProOrProPlus(): Promise<boolean> {
    try {
      if (!window.user || !window.user.firebase_user_id) {
        return false;
      }
      if (!window.rcPublicKey) {
        return false;
      }
      const purchases = Purchases.configure(window.rcPublicKey, window.user.firebase_user_id)
      const entitled = await purchases.isEntitledTo("PRO +");

      // Check for "PRO" or "PRO +" entitlement
      if (entitled) {
        return true;
      }
      const entitledPro = await purchases.isEntitledTo("PRO");

      if (entitledPro) {
        return true;
      }

      return false;

    } catch (error) {
      console.error("Error checking entitlements:", error);
      return false;
    }
  }
}
