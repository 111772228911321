<template>
  <div>
    <span class="badge badge-secondary mb-1 mb-4 py-1 pr-3">
      <div class="d-flex align-items-center" style="cursor: pointer" @click="backTapped">
        <span class="material-icons mr-1">arrow_left</span> Back
      </div>
    </span>
    <form>
      <div class="form-group mb-3">
        <label class="m-0" for="exampleInputEmail1">Email address</label>
        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
          placeholder="example@email.com" v-model="email">
        <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
      </div>
      <div class="form-group mb-3">
        <label class="m-0" for="exampleInputNameFull">Full name</label>
        <input type="name" class="form-control" id="exampleInputNameFull" aria-describedby="nameFullHelp"
          placeholder="First and last name" v-model="fullName">
      </div>
      <div class="form-group mb-3">
        <label class="m-0" for="exampleInputNameDisplay">Display Name</label>
        <input type="name" class="form-control" id="exampleInputNameDisplay" aria-describedby="nameDisplayHelp"
          placeholder="What others will see" v-model="displayName">
      </div>
      <div class="form-group mb-3">
        <label class="m-0" for="exampleInputPassword1">Password</label>
        <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password"
          v-model="password">
      </div>
      <div class="form-group mb-3">
        <label class="m-0" for="exampleInputPassword2">Confirm Password</label>
        <input type="password" class="form-control" id="exampleInputPassword2" placeholder="Password"
          v-model="confirmPassword">
      </div>
      <button @click="signUp" class="btn btn-primary">Submit</button>
    </form>
    <LoadingDialog :show-loading-dialog="loading" />
  </div>
</template>

<script lang="ts">

import LoadingDialog from "../../utils/shared_components/LoadingDialog.vue";
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    LoadingDialog
  },
  data() {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      fullName: '',
      displayName: '',
      loading: false,
    };
  },
  methods: {
    backTapped() {
      this.$store.commit('onBoarding/popPage');
    },
    async signUp(event: Event) {
      event.preventDefault()
      if (this.password != this.confirmPassword || this.password == '') {
        return alert('Passwords do not match');
      }
      this.loading = true
      try {
        await this.$store.dispatch('onBoarding/signUpWithEmail', {
          email: this.email,
          password: this.password,
          fullName: this.fullName,
          displayName: this.displayName,
        });
      } catch (e) {
        this.loading = false
      }
    },
  },
});
</script>
