import { OnBoardingPage } from "./OnBoardingPage";
// @ts-ignore
import modalBgPrint from '../../img/modal-bg-print.webp';
// @ts-ignore
import modalBgGPX from '../../img/modal-bg-GPX.webp';
// @ts-ignore
import modalBgList from '../../img/modal-bg-list.webp';
// @ts-ignore
import modalBgPro from '../../img/modal-bg-PRO.webp';
// @ts-ignore
import modalBgPurchaseProPlus from '../../img/modal-bg-purchase-pro-plus.webp';
// @ts-ignore
import modalBg3dMaps from '../../img/modal-bg-3D.jpg';
// @ts-ignore
import modalBgMapStyles from '../../img/modal-bg-map-styles.jpg';
// @ts-ignore
import modalBgMeasurementTool from '../../img/modal-bg-trail-measurement-tool.jpg';
// @ts-ignore
import modalBgAccommodations from '../../img/modal-bg-accom.jpg';
// @ts-ignore
import modalBgAmenities from '../../img/modal-bg-amenities.jpg';
// @ts-ignore
import modalBgTrailReviews from '../../img/modal-bg-trail-reviews.jpg';
// @ts-ignore
import modalBgTrailStages from '../../img/modal-bg-trail-stages.jpg';
// @ts-ignore
import modalBgTrailMap from '../../img/modal-bg-view-map.jpg';
// @ts-ignore
import modalBgWaypoints from '../../img/modal-bg-waypoints.webp';

export enum OnBoardingGoal {
  CreateAccountForPurchase = 'create_account_for_purchase',
  CreateAccountForGiftCard = 'create_account_for_gift_card',
  CreateAccount = 'create_account',
  DownloadGPX = 'download_gpx',
  DownloadPrintMaps = 'download_print_maps',
  Donate = 'donate',
  AddTrailToList = 'add_trail_to_list',
  CreatePlannedTrail = 'save_planned_trail',
  DownloadApp = 'download_app',
  MapStyles = 'map_styles',
  MeasurementTool = 'measurement_tool',
  Accommodations = 'accommodations',
  Amenities = 'amenities',
  TrailReviews = 'trail_reviews',
  TrailStages = 'trail_stages',
  TrailMap = 'trail_map',
  Waypoints = 'waypoints',
  Reviews = 'reviews',
  ReviewUpdate = 'review_update',
  Likes = 'likes',
  RedeemVoucher = 'redeem_voucher',
}

export class OnBoardingGoalUtility {
  onBoardingGoal: OnBoardingGoal

  constructor(onBoardingGoal: OnBoardingGoal) {
    this.onBoardingGoal = onBoardingGoal
  }

  static fromString(onBoardingGoal: string): OnBoardingGoalUtility {
    switch (onBoardingGoal) {
      case 'create_account_for_purchase':
        return new OnBoardingGoalUtility(OnBoardingGoal.CreateAccountForPurchase)
      case 'create_account':
        return new OnBoardingGoalUtility(OnBoardingGoal.CreateAccount)
      case 'download_gpx':
        return new OnBoardingGoalUtility(OnBoardingGoal.DownloadGPX)
      case 'download_print_maps':
        return new OnBoardingGoalUtility(OnBoardingGoal.DownloadPrintMaps)
      case 'donate':
        return new OnBoardingGoalUtility(OnBoardingGoal.Donate)
      case 'add_trail_to_list':
        return new OnBoardingGoalUtility(OnBoardingGoal.AddTrailToList)
      case 'save_planned_trail':
        return new OnBoardingGoalUtility(OnBoardingGoal.CreatePlannedTrail)
      case 'download_app':
        return new OnBoardingGoalUtility(OnBoardingGoal.DownloadApp)
      case 'map_styles':
        return new OnBoardingGoalUtility(OnBoardingGoal.MapStyles)
      case 'measurement_tool':
        return new OnBoardingGoalUtility(OnBoardingGoal.MeasurementTool)
      case 'accommodations':
        return new OnBoardingGoalUtility(OnBoardingGoal.Accommodations)
      case 'amenities':
        return new OnBoardingGoalUtility(OnBoardingGoal.Amenities)
      case 'trail_reviews':
        return new OnBoardingGoalUtility(OnBoardingGoal.TrailReviews)
      case 'trail_stages':
        return new OnBoardingGoalUtility(OnBoardingGoal.TrailStages)
      case 'trail_map':
        return new OnBoardingGoalUtility(OnBoardingGoal.TrailMap)
      case 'waypoints':
        return new OnBoardingGoalUtility(OnBoardingGoal.Waypoints)
      case 'reviews':
        return new OnBoardingGoalUtility(OnBoardingGoal.Reviews)
      case 'review_update':
        return new OnBoardingGoalUtility(OnBoardingGoal.ReviewUpdate)
      case 'likes':
        return new OnBoardingGoalUtility(OnBoardingGoal.Likes)
      case 'redeem_voucher':
        return new OnBoardingGoalUtility(OnBoardingGoal.RedeemVoucher)
    }
    throw Error('Invalid OnBoardingGoal')
  }

  requiresPro() {
    switch (this.onBoardingGoal) {
      case OnBoardingGoal.CreateAccountForPurchase:
        return false
      case OnBoardingGoal.CreateAccount:
        return false
      case OnBoardingGoal.DownloadGPX:
        return false
      case OnBoardingGoal.DownloadPrintMaps:
        return true
      case OnBoardingGoal.Donate:
        return false
      case OnBoardingGoal.AddTrailToList:
        return false
      case OnBoardingGoal.CreatePlannedTrail:
        return false
      case OnBoardingGoal.DownloadApp:
        return false
      case OnBoardingGoal.MapStyles:
        return false
      case OnBoardingGoal.MeasurementTool:
        return true
      case OnBoardingGoal.Accommodations:
        return false
      case OnBoardingGoal.Amenities:
        return false
      case OnBoardingGoal.TrailReviews:
        return false
      case OnBoardingGoal.TrailStages:
        return true
      case OnBoardingGoal.TrailMap:
        return false
      case OnBoardingGoal.Waypoints:
        return false
      case OnBoardingGoal.Reviews:
        return false
      case OnBoardingGoal.ReviewUpdate:
        return false
      case OnBoardingGoal.Likes:
        return false
      case OnBoardingGoal.RedeemVoucher:
        return false
    }
  }

  title() {
    switch (this.onBoardingGoal) {
      case OnBoardingGoal.CreateAccountForPurchase:
        return 'Create a Hiiker account'
      case OnBoardingGoal.CreateAccount:
        return 'Create a Hiiker account'
      case OnBoardingGoal.DownloadGPX:
        return 'All GPX downloads are free for all trails on Hiiker.'
      case OnBoardingGoal.DownloadPrintMaps:
        return 'Prepare your print maps.'
      case OnBoardingGoal.Donate:
        return 'Help us protect the worlds hiking trails.'
      case OnBoardingGoal.AddTrailToList:
        return 'Bucket list trails, big goals or completed trails!'
      case OnBoardingGoal.CreatePlannedTrail:
        return 'Create planned trail title'
      case OnBoardingGoal.DownloadApp:
        return 'Download app title'
      case OnBoardingGoal.MapStyles:
        return 'Login to access 100s of maps from around the world.'
      case OnBoardingGoal.MeasurementTool:
        return 'Log in to use the quick measurement tool.'
      case OnBoardingGoal.Accommodations:
        return 'Log in to view accommodation options.'
      case OnBoardingGoal.Amenities:
        return 'Log in to view amenities and refill points.'
      case OnBoardingGoal.TrailReviews:
        return 'Log in to see trail reviews.'
      case OnBoardingGoal.TrailStages:
        return 'Log in to view recommended trails stages.'
      case OnBoardingGoal.TrailMap:
        return 'Log in to view the trail map.'
      case OnBoardingGoal.Waypoints:
        return 'Log in to add and view Waypoints.'
      case OnBoardingGoal.Reviews:
        return 'Log in to add a new review for this trail.'
      case OnBoardingGoal.ReviewUpdate:
        return 'Log in to update your review for this trail.'
      case OnBoardingGoal.Likes:
        return 'Log in to add a like.'
      case OnBoardingGoal.RedeemVoucher:
        return 'Log in to redeem your voucher.'
    }
  }

  body() {
    switch (this.onBoardingGoal) {
      case OnBoardingGoal.CreateAccountForPurchase:
        return 'Create a Hiiker account to purchase Hiiker PRO and access all the features of the Hiiker app.'
      case OnBoardingGoal.CreateAccount:
        return 'With a Hiiker account you can save your favourite trails, create planned trails, and download GPX files. And... Free offline maps!'
      case OnBoardingGoal.DownloadGPX:
        return 'Looking to navigate while out on trail? Add this trail to your planned trails list so you have it ready to access from the HiiKER app?'
      case OnBoardingGoal.DownloadPrintMaps:
        return 'Get high resolution, stage by stage maps to back up your HiiKER app while out on trail. Requires HiiKER PRO.'
      case OnBoardingGoal.Donate:
        return 'We will also sync this trail to your favourites list so you have it ready to access from the HiiKER app'
      case OnBoardingGoal.AddTrailToList:
        return 'We will also sync these trails to your HiiKER app so they’re ready for you to hit the trail!'
      case OnBoardingGoal.CreatePlannedTrail:
        return 'Create planned trail body'
      case OnBoardingGoal.DownloadApp:
        return 'Download the Hiiker App now.'
      case OnBoardingGoal.MapStyles:
        return 'Access the worlds best open source outdoor maps from OSM, USGS, NZ Topo and more. '
      case OnBoardingGoal.MeasurementTool:
        return 'Select a segment of trail to check distance, elevation and estimated hike time. Handy for planning stages!'
      case OnBoardingGoal.Accommodations:
        return 'Hotels, B&B’s, hostels and campsites all listed and marked along the trail to find out where to put your head down for the night.'
      case OnBoardingGoal.Amenities:
        return 'Find out where to refill your water bottle, grab a coffee or a bite to eat.'
      case OnBoardingGoal.TrailReviews:
        return 'Learn from those who have hiked the trail before you or leave your own experiences from the trail.'
      case OnBoardingGoal.TrailStages:
        return 'Planning your stops and rest points on a multi-day hike is important, so we have created some suggestions on how to tackle the trail.'
      case OnBoardingGoal.TrailMap:
        return 'View the trail map and see the trail in relation to the surrounding area.'
      case OnBoardingGoal.Waypoints:
        return 'We have developed Waypoints to easily mark custom locations on your HiiKER maps to help navigate on future hikes! Whether it’s a meeting place, camp location or picnic spot, quickly add it and have it ready offline while on the trail!'
      case OnBoardingGoal.Reviews:
        return 'Write a review about this trail. Inform the community about your experience. What did you like? What could be improved? What should other hikers know?'
      case OnBoardingGoal.ReviewUpdate:
        return 'You need to login to update your review.'
      case OnBoardingGoal.Likes:
        return 'Add a like. Give users a thumbs up for their review and let them know you found it helpful.'
      case OnBoardingGoal.RedeemVoucher:
        return 'Redeem your voucher.'
    }
  }

  image(page: OnBoardingPage) {
    switch (this.onBoardingGoal) {
      case OnBoardingGoal.CreateAccountForPurchase:
        return modalBgGPX
      case OnBoardingGoal.CreateAccount:
        return modalBgGPX
      case OnBoardingGoal.DownloadGPX:
        return modalBgGPX
      case OnBoardingGoal.DownloadPrintMaps:
        return modalBgPrint
      case OnBoardingGoal.Donate:
        return modalBgGPX
      case OnBoardingGoal.AddTrailToList:
        return modalBgList
      case OnBoardingGoal.CreatePlannedTrail:
        return modalBgGPX
      case OnBoardingGoal.DownloadApp:
        return modalBgGPX
      case OnBoardingGoal.MapStyles:
        return modalBgMapStyles
      case OnBoardingGoal.MeasurementTool:
        return modalBgMeasurementTool
      case OnBoardingGoal.Accommodations:
        return modalBgAccommodations
      case OnBoardingGoal.Amenities:
        return modalBgAmenities
      case OnBoardingGoal.TrailReviews:
        return modalBgTrailReviews
      case OnBoardingGoal.TrailStages:
        return modalBgTrailStages
      case OnBoardingGoal.TrailMap:
        return modalBgTrailMap
      case OnBoardingGoal.Waypoints:
        return modalBgWaypoints
      case OnBoardingGoal.Reviews:
        return modalBgTrailReviews
      case OnBoardingGoal.ReviewUpdate:
        return modalBgTrailReviews
      case OnBoardingGoal.Likes:
        return modalBgTrailReviews
      case OnBoardingGoal.RedeemVoucher:
        return modalBgPro
    }
  }
}
