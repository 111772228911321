import 'firebaseui/dist/firebaseui.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/app-check';
import * as firebaseui from 'firebaseui';

if (firebase.apps.length === 0) {
  firebase.initializeApp(window.firebaseConfigs);
  window.firebase = firebase;
}
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

const provider = '6LedPQEqAAAAADwAEbDFIdOONwyKpedA3vh3iqj4';

firebase.appCheck().activate(provider, true);
firebase.appCheck().setTokenAutoRefreshEnabled(true);

function handleSigninError(error, redirectUrl) {
  window.location = redirectUrl;
}

firebase.hiikerFirebaseAuthUIStart = (elementId) => {
  const ui = new firebaseui.auth.AuthUI(firebase.auth());
  ui.start(elementId, {
    callbacks: {
      signInSuccessWithAuthResult: () => {
        firebase.auth().currentUser.getIdToken().then((token) => {
          const redirectUrl = `/firebase/auth?accessToken=${token}`;
          window.location = redirectUrl;
        });
        return false;
      },
    },
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID
    ],
    credentialHelper: firebaseui.auth.CredentialHelper.NONE,
    tosUrl: '/terms-of-service',
    privacyPolicyUrl: '/privacy',
    signInFlow: 'popup',
  });
};

firebase.hiikerSignInWithApple = (signInSuccessUrl) => {
  const provider = new firebase.auth.OAuthProvider('apple.com');
  provider.addScope('email');
  provider.addScope('name');
  const redirectUrl = signInSuccessUrl || '/profile';
  firebase
    .auth()
    .signInWithPopup(provider)
    .then(async (userCredential) => {
      const idToken = await userCredential.user.getIdToken();
      const redirectUrl2 = `/firebase/auth?accessToken=${idToken}`;
      window.location = redirectUrl2;
    })
    .catch((error) => {
      handleSigninError(error, redirectUrl);
    });
};

export default firebase;
