import { GraphQLClient } from 'graphql-request';

import { token } from '../helpers/axios_client';

const endpoint = '/graphql';

// ... or create a GraphQL client instance to send requests
export default new GraphQLClient(endpoint, {
  headers: {
    'X-CSRF-Token': token.content,
    App: 'Web',
  },
});
