<template>
  <div>
    <p class="display-4 mb-2"><strong>{{ title }}</strong></p>
    <p>{{ body }}</p>
  </div>
</template>

<script lang="ts">

import ahoy from 'ahoy.js';
import { defineComponent } from 'vue';
import { OnBoardingGoalUtility } from '../../utils/models/OnBoardingGoal';

export default defineComponent({
  computed: {
    onBoardingGoalUtility: {
      get() {
        return new OnBoardingGoalUtility(this.$store.state.onBoarding.onBoardingGoal);
      },
    },
    title: {
      get() {
        return this.onBoardingGoalUtility.title();
      },
    },
    body: {
      get() {
        return this.onBoardingGoalUtility.body();
      },
    },
  },
  mounted() {
    ahoy.track('onboarding_detail_page_mounted');
  },
});
</script>

<style scoped></style>
