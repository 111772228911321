import Vue from "vue";
import Vuex, { Store } from "vuex";
import OnBoardingModule, { OnBoardingState } from "./modules/OnBoardingModule";

Vue.use(Vuex)

export interface RootState {
  onBoarding: OnBoardingState
}

const store: Store<RootState> = new Vuex.Store({
  modules: {
    onBoarding: new OnBoardingModule()
  }
})

store.subscribe(async (mutation, state) => {
  if (mutation.type === "onBoarding/setPrice") {

  }
})

export default store;
