<template>
  <div>
    <div class="modal fade" id="on-boarding-dialog" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <button type="button" class="close btn-link" data-bs-dismiss="modal">
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-body">
            <div class="row p-0 m-0 min-height-80">
              <div class="left-col p-2 col-12 col-md-6 d-flex align-items-center justify-content-center" style="background-size: cover" :style="{ 'backgroundImage': 'url(' + backgroundImage + ')' }">
                <div class="image-gradient d-block d-md-none"></div>
                <div class="content-container">
                  <OnBoardingDetailPage />
                </div>
              </div>
              <div class="right-col p-2 col-12 col-md-6 d-flex align-items-center justify-content-center">
                <div class="content-container">
                  <template v-if="topPageIsLogin">
                    <OnBoardingLoginPage/>
                  </template>
                  <template v-if="topPageIsSignUp">
                    <OnBoardingSignUpPage/>
                  </template>
                  <template v-if="topPageIsDownloadGPX">
                    <OnBoardingDownloadGPXPage/>
                  </template>
                  <template v-if="topPageIsDownloadPrintMaps">
                    <OnBoardingDownloadPrintMapsPage/>
                  </template>
                  <template v-if="topPageIsConfirmDetails">
                    <OnBoardingConfirmDetailsPage/>
                  </template>
                  <template v-if="topPageIsEmailSignUp">
                    <OnBoardingEmailSignUpDetailsPage/>
                  </template>
                  <template v-if="topPageIsEmailLogin">
                    <OnBoardingEmailLoginPage/>
                  </template>
                  <template v-if="topPageIsSuccess">
                    <OnBoardingSuccessPage/>
                  </template>
                  <template v-if="topPageIsAddTrailToList">
                    <OnBoardingAddToListPage/>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { defineComponent } from 'vue';
import { OnBoardingGoalUtility } from '../utils/models/OnBoardingGoal';
import { OnBoardingPage } from '../utils/models/OnBoardingPage.ts';
import OnBoardingAddToListPage from './components/OnBoardingAddToListPage.vue';
import OnBoardingConfirmDetailsPage from './components/OnBoardingConfirmDetailsPage.vue';
import OnBoardingDetailPage from './components/OnBoardingDetailPage.vue';
import OnBoardingDownloadGPXPage from './components/OnBoardingDownloadGPXPage.vue';
import OnBoardingDownloadPrintMapsPage from './components/OnBoardingDownloadPrintMapsPage.vue';
import OnBoardingEmailLoginPage from './components/OnBoardingEmailLoginPage.vue';
import OnBoardingEmailSignUpDetailsPage from './components/OnBoardingEmailSignUpDetailsPage.vue';
import OnBoardingLoginPage from './components/OnBoardingLoginPage.vue';
import OnBoardingSignUpPage from './components/OnBoardingSignUpPage.vue';
import OnBoardingSuccessPage from './components/OnBoardingSuccessPage.vue';

export default defineComponent({
  components: {
    OnBoardingLoginPage,
    OnBoardingSignUpPage,
    OnBoardingDownloadGPXPage,
    OnBoardingDetailPage,
    OnBoardingDownloadPrintMapsPage,
    OnBoardingConfirmDetailsPage,
    OnBoardingEmailSignUpDetailsPage,
    OnBoardingSuccessPage,
    OnBoardingAddToListPage,
    OnBoardingEmailLoginPage,
  },
  computed: {
    onBoardingGoalUtility: {
      get() {
        return new OnBoardingGoalUtility(this.$store.state.onBoarding.onBoardingGoal);
      },
    },
    backgroundImage: {
      get() {
        return this.onBoardingGoalUtility.image(this.topPage);
      },
    },
    topPage: {
      get() {
        return this.$store.getters['onBoarding/lastPage'];
      },
    },
    topPageIsAddTrailToList: {
      get() {
        return this.$store.getters['onBoarding/lastPage'] === OnBoardingPage.AddTrailToList;
      },
    },
    topPageIsSuccess: {
      get() {
        return this.$store.getters['onBoarding/lastPage'] === OnBoardingPage.Success;
      },
    },
    topPageIsEmailSignUp: {
      get() {
        return this.$store.getters['onBoarding/lastPage'] === OnBoardingPage.EmailSignUp;
      },
    },
    topPageIsEmailLogin: {
      get() {
        return this.$store.getters['onBoarding/lastPage'] === OnBoardingPage.EmailLogin;
      },
    },
    topPageIsLogin: {
      get() {
        return this.$store.getters['onBoarding/lastPage'] === OnBoardingPage.LoginOptions;
      },
    },
    topPageIsSignUp: {
      get() {
        return this.$store.getters['onBoarding/lastPage'] === OnBoardingPage.SignUpOptions;
      },
    },
    topPageIsDownloadGPX: {
      get() {
        return this.$store.getters['onBoarding/lastPage'] === OnBoardingPage.DownloadGPX;
      },
    },
    topPageIsDownloadPrintMaps: {
      get() {
        return this.$store.getters['onBoarding/lastPage'] === OnBoardingPage.DownloadPrintMaps;
      },
    },
    topPageIsConfirmDetails: {
      get() {
        return this.$store.getters['onBoarding/lastPage'] === OnBoardingPage.ConfirmDetails;
      },
    },
  },
});
</script>

<style scoped>

.min-height-80{
  min-height: 80vh;
}

.modal{
  overflow: scroll;
}
.modal-dialog {
  width: 92%;
  max-width: none;
  margin: auto;
}

.modal-content {
  height: auto;
  min-height: 80vh;
  border-radius: 0;
}

.modal-body{
  padding: 0;
}

.image-gradient{
  background: rgb(32,32,32);
  background: linear-gradient(0deg, rgba(32,32,32,1) 0%, rgba(32,32,32,0) 100%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.left-col{
  background-repeat: no-repeat;
  background-position: center left;
}

.close{
  position: absolute;
  top: 10px;
  right: 18px;
  z-index: 100;
  font-size: 4em;
  width: 50px;
  height: 50px;
}

.content-container{
  width: 60%;
  z-index: 1;
}

/deep/ .form-control{
  border: none;
  border-bottom: 1px solid #4f4f4f;
  border-radius: 0;
  height: 2.5em;
  padding: 0;
}

/deep/ .form-control:disabled, .form-control[readonly] {
  background-color: transparent !important;
}

@media (min-width: 2000px) {
  .left-col{
    background-repeat: no-repeat;
    background-position: center left;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

@media (max-width: 1200px) {
  .content-container{
    width: 80%;
  }

  .left-col{
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}

@media (max-width: 769px) {
  .left-col{
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
    padding: 6em 2em 0 !important;
  }

  .right-col{
    padding: 2em 2em 6em !important;
  }

  .close{
    top: .2em;
    right: .2em;
  }
}

@media (max-width: 420px) {
  .content-container{
    width: 100%;
  }

  .left-col {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

}

</style>
